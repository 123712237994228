import NavbarDashboard from "../components/navbarDashboard/NavbarDashboard";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import YearSelect from "../components/YearSelector/YearSelect";
import CardDashboard from "../components/CardDisplay/CardDashBoard";

const RepPageReport = () => {
  const quarters = {
    "JAN-FEB-MAR": "1",
    "APR-MAY-JUN": "2",
    "JUL-AUG-SEP": "3",
    "OCT-NOV-DEC": "4",
  };
  const [reportData, setReportData] = React.useState(null);
  const [year, setYear] = React.useState(new Date().getFullYear().toString());
  const updateYear = (value) => {
    setYear(value);
  };
  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
    };
    fetch(
      `https://harbinger.psgtech.ac.in/api/report/${year}/`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setReportData(data);
        console.log("Hellloooooo!!!!!Noww");
        console.log(data);
      });
  }, []);
  return (
    <div>
      <NavbarDashboard />
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "7%",
          marginBottom: "-11%",
          marginRight: "5%",
        }}
      >
        <YearSelect yearUpdate={updateYear} />
      </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <div style={{ marginLeft: "15%" }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
              color: "#FFFFFF",
            }}
          >
            QUARTER
          </Typography>
          <Typography
            variant="h3"
            component="div"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
              color: "#79DCFF",
              textTransform: "lowercase",
              "&:first-letter": {
                textTransform: "capitalize", // Capitalize only the first letter
              },
            }}
          >
            SELECTOR
          </Typography>
        </div>
        <div style={{ marginRight: "5%", marginTop: "4%" }}>
          <Stack direction="row" spacing={10}>
            <Stack spacing={3}>
              <Stack direction="row" spacing={2}>
                {reportData ? (
                  <>
                    <CardDashboard
                      currentYear={year}
                      id={reportData[0].report ? reportData[0].report.id : null}
                      index={quarters[reportData[0].quarter]}
                      stage={
                        reportData[0].report
                          ? reportData[0].report.stage ===
                            "ACTION_FROM_REPRESENTATIVE"
                            ? "New"
                            : reportData[0].report.stage === "ACTION_FROM_HOD"
                            ? "Awaiting"
                            : reportData[0].report.stage === "ACTION_COMPLETED"
                            ? "Completed"
                            : null
                          : "Closed"
                      }
                      currentQuarter={reportData[0].quarter}
                      isEdit={
                        reportData[0].report !== null &&
                        reportData[0].report.stage ===
                          "ACTION_FROM_REPRESENTATIVE"
                          ? true
                          : false
                      }
                      isDownload={reportData[0].report !== null ? true : false}
                    />
                    <CardDashboard
                      currentYear={year}
                      id={reportData[1].report ? reportData[1].report.id : null}
                      index={quarters[reportData[1].quarter]}
                      stage={
                        reportData[1].report
                          ? reportData[1].report.stage ===
                            "ACTION_FROM_REPRESENTATIVE"
                            ? "New"
                            : reportData[1].report.stage === "ACTION_FROM_HOD"
                            ? "Awaiting"
                            : reportData[1].report.stage === "ACTION_COMPLETED"
                            ? "Completed"
                            : null
                          : "Closed"
                      }
                      currentQuarter={reportData[1].quarter}
                      isEdit={
                        reportData[1].report !== null &&
                        reportData[1].report.stage ===
                          "ACTION_FROM_REPRESENTATIVE"
                          ? true
                          : false
                      }
                      isDownload={reportData[1].report !== null ? true : false}
                    />
                  </>
                ) : null}
              </Stack>
              <Stack direction="row" spacing={2}>
                {reportData ? (
                  <>
                    <CardDashboard
                      currentYear={year}
                      id={reportData[2].report ? reportData[2].report.id : null}
                      index={quarters[reportData[2].quarter]}
                      stage={
                        reportData[2].report
                          ? reportData[2].report.stage ===
                            "ACTION_FROM_REPRESENTATIVE"
                            ? "New"
                            : reportData[2].report.stage === "ACTION_FROM_HOD"
                            ? "Awaiting"
                            : reportData[2].report.stage === "ACTION_COMPLETED"
                            ? "Completed"
                            : null
                          : "Closed"
                      }
                      currentQuarter={reportData[2].quarter}
                      isEdit={
                        reportData[2].report !== null &&
                        reportData[2].report.stage ===
                          "ACTION_FROM_REPRESENTATIVE"
                          ? true
                          : false
                      }
                      isDownload={reportData[2].report !== null ? true : false}
                    />
                    <CardDashboard
                      currentYear={year}
                      id={reportData[3].report ? reportData[3].report.id : null}
                      index={quarters[reportData[3].quarter]}
                      stage={
                        reportData[3].report
                          ? reportData[3].report.stage ===
                            "ACTION_FROM_REPRESENTATIVE"
                            ? "New"
                            : reportData[3].report.stage === "ACTION_FROM_HOD"
                            ? "Awaiting"
                            : reportData[3].report.stage === "ACTION_COMPLETED"
                            ? "Completed"
                            : null
                          : "Closed"
                      }
                      currentQuarter={reportData[3].quarter}
                      isEdit={
                        reportData[3].report !== null &&
                        reportData[3].report.stage ===
                          "ACTION_FROM_REPRESENTATIVE"
                          ? true
                          : false
                      }
                      isDownload={reportData[3].report !== null ? true : false}
                    />
                  </>
                ) : null}
              </Stack>
            </Stack>
            <YearSelect yearUpdate={updateYear} />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default RepPageReport;
