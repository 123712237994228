import * as React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { useEffect } from "react";
import { useContext } from "react";
import { ReportContentContext } from "../../providers/reportContentProvider";
import { useParams } from "react-router-dom";

Quill.register("modules/imageResize", ImageResize);

export default function QuillEditor({ subTitle }) {
  const { id } = useParams();

  const { content, setSubTitle, getSubTitle } =
    useContext(ReportContentContext);

  const quillEditor = React.useRef();
  const [value, setValue] = React.useState(getSubTitle(subTitle));

  const quillImageCallBack = () => {
    console.log("ADDING IMAGE");
    const editor = quillEditor.current.getEditor();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      var formdata = new FormData();
      formdata.append("file", file);
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")} `,
          },
          body: formdata,
        };
        const response = await (
          await fetch(
            `https://harbinger.psgtech.ac.in/api/report/${id}/upload`,
            requestOptions
          )
        ).json();
        editor.insertEmbed(
          editor.getSelection(),
          "image",
          `https://harbinger.psgtech.ac.in/api/object/${response.id}`
        );
      } catch (err) {
        console.log("upload err:", err);
      }
    };
  };

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const module = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        ["link", "image"],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ],
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    },
  };

  useEffect(() => {
    setSubTitle(subTitle, value);
  }, [value]);

  useEffect(() => {
    setValue(getSubTitle(subTitle));
  }, [subTitle]);

  useEffect(() => {
    if (quillEditor.current) {
      quillEditor.current
        .getEditor()
        .getModule("toolbar")
        .addHandler("image", quillImageCallBack);
    }
  }, []);

  return (
    <div>
      <ReactQuill
        ref={quillEditor}
        style={{ background: "#FFFFFF" }}
        modules={module}
        theme="snow"
        value={value}
        onChange={setValue}
      ></ReactQuill>
    </div>
  );
}
