import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./RepEditPage.css";
import Category from "../components/RepEditCategories/Category";
import QuillEditor from "../components/RepEditCategories/QuillEditor.js";
import NavbarLogin from "../components/navbarDashboard/NavbarDashboard.js";
import { Button } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import { Await, useFetcher } from "react-router-dom";
import {
  ALUMNI_EVENTS,
  AWARDS,
  AWARNESS_PROGRAM,
  COVER_STORY,
  EXCHANGE_PROGRAMS,
  FOCUS_ARTICLES,
  PAPER_PRESENTATIONS,
  RESEARCH_INITIATIVES,
  ReportContentContext,
  ReportContentContextProvider,
  SPORTS,
  STUDENT_ACTIVITIES,
  WORKSHOP,
} from "../providers/reportContentProvider";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
}));

export default function RepEditPage() {
  const navigation = useNavigate();
  const { id } = useParams();
  const { title } = useParams();
  const { currentYear } = useParams();
  const { currentQuarter } = useParams();
  const handleUpdate = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
      body: JSON.stringify({ data: { content } }),
    };
    fetch(
      `https://harbinger.psgtech.ac.in/api/report/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        navigation(`/sec/${id}/${currentYear}/${currentQuarter}`);
      });
  };
  const { setContent, content } = React.useContext(ReportContentContext);
  const [clickedItem, setClickedItem] = React.useState("");

  const sections = [
    { name: "Cover Story", key: COVER_STORY },
    { name: "Focus Articles", key: FOCUS_ARTICLES },
    { name: "Workshops", key: WORKSHOP },
    { name: "Paper Presentations", key: PAPER_PRESENTATIONS },
    {
      name: "Research Initiatives",
      key: RESEARCH_INITIATIVES,
    },
    { name: "Awareness programs", key: AWARNESS_PROGRAM },
    { name: "Achievements", key: AWARDS },
    { name: "Alumni events", key: ALUMNI_EVENTS },
    { name: "Student activities and Achievements", key: STUDENT_ACTIVITIES },
    {
      name: "International Programs",
      key: EXCHANGE_PROGRAMS,
    },
    // { name: "Sports", key: SPORTS },
  ];

  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    fetch(
      `https://harbinger.psgtech.ac.in/api/report/${currentYear}/${currentQuarter}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setContent((prevContent) => ({ ...prevContent, ...data.content }));
        setClickedItem(sections.find((section) => section.name === title).key);
      });
  }, []);

  return (
    <>
      <NavbarLogin />
      <Box sx={{ flexGrow: 1, marginTop: "100px", marginBottom: "5%" }}>
        <Grid container spacing={2}>
          <Grid item xs={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
            <Item>
              {sections.map((section) => (
                <>
                  <Category
                    key={section.key}
                    category={section.name}
                    currentlySelected={
                      section.key === clickedItem ? true : false
                    }
                    handleClick={() => setClickedItem(section.key)}
                  />
                </>
              ))}
            </Item>
          </Grid>
          {/* <Button
            variant="contained"
            style={{
              position: "fixed",
              bottom: 16,
              right: 500,
              color: "#ffffff",
              backgroundColor: "#035088",
            }}
          >
            Submit
          </Button> */}
          <Grid item xs={9}>
            <Item>
              <QuillEditor subTitle={clickedItem} />
            </Item>
          </Grid>
          <Card
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              backgroundColor: "#E2EBFC", // Set your desired card background color
              width: "100%",
              height: "8%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //opacity: "80%",
            }}
          >
            <CardContent>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#035088",
                  marginTop: "8px",
                  zIndex: 1,
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </>
  );
}
