import React from "react";
import "./ProgressBar.css";
import { useParams } from "react-router-dom";

const ProgressBar = () => {
  const { id } = useParams();
  const [reportData, setReportData] = React.useState(null);
  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
    };
    fetch(
      `https://harbinger.psgtech.ac.in/api/report/${id}/steps`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setReportData(data);
      });
  }, []);
  return (
    <div className="popup-container">
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <div className="timeline">
          {reportData &&
            reportData.map((item, index) => (
              <div className="timeline-item" key={index}>
                <div className="timeline-item-content">
                  <div className="circle"></div>
                  <div className="text">
                    <p>Status: {item.status}</p>
                    {item.comment !== "" && <p>Comment: {item.comment}</p>}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
