import ReportButtonAdmin from "../components/ReportButtonAdmin/ReportButtonAdmin";
import NavbarDashboard from "../components/navbarDashboard/NavbarDashboard";
import AdminDepCard from "../components/AdminDepCard/AdminDepCard";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function AdminEditPage() {
  const { currentYear } = useParams();
  const { currentQuarter } = useParams();
  const [allDepts, setAllDepts] = React.useState([]);
  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
    };
    fetch(
      `https://harbinger.psgtech.ac.in/api/admin/report/${currentYear}/${currentQuarter}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setAllDepts(data);
      })
      .catch((error) => {
        console.error("Error during fetch all dept in admin!", error);
      });
  }, []);
  // const departments = [
  //   {
  //     title: "AMCS",
  //     content: "Completed",
  //   },
  //   {
  //     title: "BE-CSE",
  //     content: "Not Completed",
  //   },
  //   {
  //     title: "BTECH-IT",
  //     content: "Completed",
  //   },
  //   {
  //     title: "PRODUCTION",
  //     content: "Not Completed",
  //   },
  //   {
  //     title: "CIVIL",
  //     content: "Completed",
  //   },
  //   {
  //     title: "MECHANICAL",
  //     content: "Not Completed",
  //   },
  //   {
  //     title: "BIO-MEDICAL",
  //     content: "Completed",
  //   },
  //   {
  //     title: "TEXTILE",
  //     content: "Completed",
  //   },
  //   {
  //     title: "MCA",
  //     content: "Not Completed",
  //   },
  // ];
  const handleSubmit = (selectedDate, id) => {
    const date = selectedDate.$d.toISOString().slice(0, -5) + "Z";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
      body: JSON.stringify({ deadline: date }),
    };
    fetch(
      `https://harbinger.psgtech.ac.in/api/admin/deparment/${id}/extend`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Deadline extended...");
      })
      .catch((error) => {
        console.error("Error during extending the deadline", error);
        // Handle the error appropriately, e.g., show an error message to the user
      });
    //console.log("Selected date:", timestamp.toISOString());
    // Handle the selected date here
  };
  return (
    <>
      <NavbarDashboard />
      <div className="grid-container" style={{ marginTop: "10%" }}>
        {allDepts.map((card, index) => (
          <AdminDepCard
            key={index}
            title={card.id}
            content={
              card.reports[0] && card.reports[0].stage === "ACTION_COMPLETED"
                ? "Completed"
                : "In Progress"
            }
            onSubmit={handleSubmit}
          />
        ))}
      </div>
    </>
    /*
    <div style={{ marginTop: "100px" }}>
      <NavbarDashboard />
      <ReportButtonAdmin />
      <AdminDepCard />
      <AdminDepCard />
      <AdminDepCard />
      <AdminDepCard />
      <AdminDepCard />
    </div>*/
  );
}
