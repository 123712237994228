import * as React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Download from "./DownLoadButtonHOD";
import { Dialog, DialogTitle, TextField } from "@mui/material";
import LoginButton from "../loginButton/LoginButton";
import "./buttonCardHOD.css";
import { useNavigate, useParams } from "react-router-dom";
function ButtonCardHOD(props) {
  let { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [comments, setComments] = React.useState("");
  const [clicked, setClicked] = React.useState("");
  const handleSubmit = (value) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
      body: JSON.stringify({ data: { status: value, comment: comments } }),
    };
    fetch(
      `https://harbinger.psgtech.ac.in/api/report/${id}/review`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        navigate("/hod");
      });
  };
  const handleAccept = () => {
    setOpen(true);
    setClicked("ACCEPTED");
    handleSubmit("ACCEPTED");
  };
  const handleDecline = () => {
    setOpen(true);
    setClicked("DECLINED");
    handleSubmit("DECLINED");
  };
  const commentChange = (text) => {
    setComments(text);
    console.log(text);
  };
  const handleClose = () => {
    setOpen(false);
    props.actionClose();
  };

  return (
    <div>
      {/* <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "100px",
      }}
    >
      <Card sx={{ margin: "20px", padding: "50px", borderRadius: "15px" }}>
        <CardActions>
          <Stack direction="column" spacing={5}>
            <Stack direction="row" spacing={7}>
              <Button
                className="approval-button"
                sx={{
                  backgroundColor: "#035088",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#084265",
                    borderColor: "#084265",
                  },
                }}
                onClick={handleAccept}
              >
                Accept
              </Button>
              <Button
                className="approval-button"
                sx={{
                  backgroundColor: "#035088",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#084265",
                    borderColor: "#084265",
                  },
                }}
                onClick={handleDecline}
              >
                Decline
              </Button>
            </Stack>
            <Download />
          </Stack>
        </CardActions>
      </Card>
    </div> */}
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { backgroundColor: "#017D95" } }}
      >
        <DialogTitle sx={{ color: "#FFFFFF" }}>Comments</DialogTitle>
        <div style={{ margin: "20px" }}>
          <Stack direction={"column"}>
            <TextField
              id="outlined-basic"
              label="Enter Comments"
              variant="outlined"
              onChange={(event) => commentChange(event.target.value)}
              rows={10}
              multiline
              sx={{
                marginBottom: "10px",
                color: "#FFFFFF",
                backgroundColor: "#5fb1cf",
                borderRadius: "5px",
              }}
            />
            <Stack direction="row" spacing={10}>
              <LoginButton name="Accept" submit={handleAccept} />
              <LoginButton name="Decline" submit={handleDecline} />
            </Stack>
          </Stack>
        </div>
      </Dialog>
    </div>
  );
}

export default ButtonCardHOD;
